const en = {
  jobDescription: {
    descriptionTitle: 'DESCRIPTION',
    applyButtonText: 'APPLY',
    applyAgainButtonText: 'APPLY AGAIN',
    continueButtonText: 'CONTINUE',
    alreadyApplied: 'YOU HAVE ALREADY APPLIED',
    termsAndConditionsText:
      'By checking this box, you are agreeing to our <a href="https://www.apploi.com/terms-conditions/" target="_blank">Terms and Conditions</a>, <a href="https://www.apploi.com/cookie-policy/" target="_blank">Cookies</a> and <a href="https://www.apploi.com/privacy_policy/" target="_blank">Privacy</a> policies. You also consent to receive SMS messages (including text messages and MMS) from [Company Name] regarding your employment application and/or other opportunities. Messaging and data rates may apply. Messaging frequency may vary. To opt-out of SMS messages, you can reply and text the word "STOP".',
    location: 'LOCATION',
    about: 'ABOUT',
    industry: 'Industry',
    companyWebsite: 'Company Website',
    photos: 'PHOTOS',
  },
  jobDescriptionFullApply: {
    school: 'School',
    major: 'Major (Optional)',
    startDate: 'Start Date',
    endDate: 'End Date',
    educationLevel: 'Education Level',
    educationGraduated: 'I graduated from this school',
    educationAddMore: '+ Add more education',
    educationAddDetailsTitle: 'Add details about your education history:',
    educationAddDetailsSubTitle: '(Auto-filled if resume is uploaded)',
    employer: 'Employer',
    jobTitle: 'Title',
    jobDescription: 'Description',
    presentJob: 'This is my present job',
    addMoreExperience: '+ Add more experience',
    jobAddDetailsTitle: 'Add details about your work experience:',
    jobAddDetailsSubTitle: '(Auto-filled if resume is uploaded)',
    firstName: 'First Name',
    lastName: 'Last Name',
    address: 'Address',
    city: 'City',
    state: 'State',
    postalCode: 'Postal Code',
    country: 'Country',
    email: 'Email',
    phone: 'Phone',
    expand: 'EXPAND',
    collapse: 'COLLAPSE',
    expandAll: 'EXPAND ALL',
    collapseAll: 'COLLAPSE ALL',
    allChangesSaved: 'All changes saved.',
    saving: 'Saving… Please wait a moment.',
    interviewQuestions: 'Interview Questions',
    applyNow: 'Apply Now',
    submit: 'SUBMIT',
    backToTop: 'Back To Top',
    uploadResume: 'UPLOAD RESUME',
    uploadResumeOptional: '(optional)',
    uploadResumeWarning:
      'Resume upload is required in order to apply for this job!',
    uploadCompleteTitle: 'UPLOADING COMPLETE!',
    uploadCompleteText:
      'Make sure to review the fields in your application. Resume upload is not always perfect.',
    uploadError1:
      'There was an error while processing your resume! Please try again.',
    uploadError2:
      'Please upload a file in supported format. Supported formats are .pdf, .docx, and .doc.',
    signatureIndication: 'Use your mouse to sign above',
    signatureClear: 'CLEAR',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',
    weekdays: 'Weekdays',
    weeknights: 'Weeknights',
    weekends: 'Weekends',
    anytime: 'Anytime',
    allDay: 'All day',
    to: 'To',
    optional: 'Optional',
    selectDate: 'Select a date',
    videoMinute: 'minute',
    videoMinutes: 'minutes',
    videoNoLimits: 'No limit',
    videoNew: 'Upload New video',
    videoLimit: 'Time Limit',
    videoText1:
      'For photo identification purpose, please click record & instantly submit.',
    videoText2: 'Video Interview Tips',
    videoText3:
      'Unlike an interview, you can re-record your response as many times as you like!',
    videoText4: 'Smile and look directly into the camera.',
    videoText5:
      'Pay attention to your language: don’t swear and don’t use slang.',
    videoText6: 'Find a place to record with good light and no distractions.',
    videoText7: 'Dress appropriately!',
    sliderLikely: 'LIKELY',
    sliderVery: 'VERY',
    sliderNot: 'NOT',
    sliderClear: 'Clear',
    incorrectTimeAvailability:
      'Incorrect value was selected for your Time Availability. Please try again.',
    autoSaveFailed: 'Autosave Failed',
    personalQuestionsAutoSaveFailed: 'Personal Questions Autosave failed',
    remove: 'Remove',
    submitConfirmation: 'ARE YOU SURE YOU WANT TO SUBMIT?',
    submitOk: 'OK',
    submitCancel: 'CANCEL',
    submitSuccessful: 'You have successfully submitted the form.',
    submitValidationError:
      'Something went wrong. Please reload the page and try again!',
    submitValidationIncomplete:
      'Please complete all sections. Incomplete sections will be marked in red!',
    submitValidationPhoneError: 'Please enter a valid phone number.',
  },
  jobSalary: {
    compensation: 'Compensation',
    upTo: 'Up to',
    perHour: 'per hour',
    perDiem: 'per diem',
    perMonth: 'per month',
    perYear: 'per year',
  },
  jobExperience: {
    experienceTitle: 'Minimum Years of Experience',
    year: 'year',
    years: 'years',
    none: 'None',
  },
  jobLevelOfEducation: {
    educationTitle: 'Minimum Level of Education',
    certificate: 'Certificate',
    highSchool: 'High School',
    associate: 'Associate',
    bachelors: 'Bachelors',
    master: 'Master',
    other: 'Other',
    none: 'None',
  },
  account: {
    myAccount: 'MY ACCOUNT',
    signOut: 'SIGN OUT',
  },
  internal: {
    searchTitle: 'SEARCH FOR OPEN POSITIONS',
    searchInput: 'Enter a keyword',
    searchLocation: 'Search places...',
    searchButton: 'SEARCH',
    searchLoading: 'Loading...',
    companyProfileApply: 'APPLY',
    companyProfileDayAgo: 'day ago',
    companyProfileDaysAgo: 'days ago',
    companyProfileToday: 'today',
    industryFilterTitle: 'INDUSTRY',
    industryFilterSelectAll: 'Select all',
    industryFilterUnselectAll: 'Unselect all',
    'Full Time': 'Full Time',
    'Part Time': 'Part Time',
    All: 'All',
    cityFilterTitle: 'CITY',
    cityFilterCityCenter: 'City Center',
    cityFilterCities: 'Cities',
    cityFilterRadius: 'Radius',
    milesFilterCities: 'Miles',
  },
  jobDescriptionQuickApply: {
    applyInstantly: 'APPLY INSTANTLY',
    redirectMessage:
      "You will now be redirected to the company's profile page to see more jobs.",
    applicationSubitted: 'YOUR APPLICATION HAS BEEN SUBMITTED',
    uploadError:
      'Please upload a file in supported format. Supported formats are .pdf, .docx, and .doc.',
    alreadyApplied: 'YOU HAVE ALREADY APPLIED',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email Address',
    phone: 'Phone Number (optional)',
    phone2: 'Phone Number',
    attachResume: 'ATTACH RESUME',
    optional: '(optional)',
    required: '(required)',
    sumbit: 'SUBMIT',
    quickApply: '1-CLICK APPLY',
    quickApplyInfoText:
      'If this is not you or you would like to apply again, please',
    quickApplyInfoText2:
      'If this is not you or you would like to apply again, please click below.',
    quickApplyClick: 'click here.',
    resumeValidation:
      'Resume upload is required in order to apply for this job!',
    applyNow: 'APPLY NOW',
    applyAgain: 'APPLY AGAIN',
    loading: 'Loading...',
  },
  shareModal: {
    copyLink: 'Copy Link',
    copied: 'Copied to clipboard!',
  },
  registerModal: {
    emailValidation1: 'Your email address has already been used.',
    emailValidation2: 'Please enter a valid email address',
    apply: 'APPLY',
    email: 'Email',
    password: 'Password',
    passwordText1: 'Password must be ',
    passwordText2: '8 characters long ',
    passwordText3: 'and contain a ',
    passwordText4: 'numeral, ',
    passwordText5: 'lowercase ',
    passwordText6: 'letter, and ',
    passwordText7: 'uppercase ',
    passwordText8: 'letter.',
    continueBtn: 'CONTINUE',
    login: 'LOGIN',
  },
  loginModal: {
    incorrectLogin: 'Your email or password was incorrect.',
    emailValidation: 'Please enter a valid email address',
    email: 'Email',
    password: 'Password',
    forgotPassword: 'Forgot your password',
    apply: 'APPLY',
    signOutConfirmation: 'ARE YOU SURE YOU WANT TO SIGN OUT?',
    signOutOk: 'OK',
    signOutCancel: 'CANCEL',
  },
  navigation: {
    openPositions: 'OPEN POSITIONS',
    companyDescription: 'COMPANY DESCRIPTION',
    jobDescription: 'JOB DESCRIPTION',
    location: 'LOCATION',
    skills: 'SKILLS',
    videos: 'VIDEOS',
    photos: 'PHOTOS',
    apply: 'APPLY',
    jobsAt: 'Jobs at',
  },
  resetNewPassword: {
    passwordResetLinkSent: 'PASSWORD RESET LINK SENT TO: ',
    enterEmailAddress: 'Please enter your email address.',
    sendResetLink: 'Send Password Reset Link',
    backToLogin: 'Back to Login',
    passwordReseted:
      'YOUR PASSWORD HAS BEEN RESET, PLEASE VISIT THE PREVIOUS PAGE YOU WERE ON OR CLICK THE LINK AGAIN IN YOUR EMAIL TO COMPLETE THE APPLICATION PROCESS.',
    passwordErrorMessage: 'Cannot use reset password link more than once.',
    addNewPassword: 'ADD NEW PASSWORD',
    resetPassword: 'RESET PASSWORD',
  },
  getVerified: {
    nursysApiTitleText: 'GET AHEAD, GET VERIFIED',
    svApiTitleText: 'Get Ahead, Get Verified',
    nursysApiSubtitleText: '{{verificationType}} LICENSE VERIFICATION',
    svApiSubtitleText: '{{verificationType}} CERTIFICATE VERIFICATION',
    nursysApiDescriptionText:
      'Get ahead of the game and enhance your application by instantly verifying your license with the {{state}} board.',
    svApiDescriptionText:
      "If you're a candidate or employee, submit your credentials to be checked against the {{state}} board.\n\n Employers, upload and review verification details to ensure the proper certificate has been found.",
    nursysApiFooterTitle: 'This is optional.',
    nursysApiFooterText:
      'You can verify your credentials with Nursing Home Facility later if your application advances to next steps.',
    svApiFooterText:
      'Candidates, this is optional. You can verify your credentials with Nursing Home Facility later if needed.',
    buttonText: 'GET STARTED',
  },
  verification: {
    backToEmployee: 'BACK TO EMPLOYEE PROFILE',
    employeeExtraText: 'and the Employee License details have been updated.',
    applicantExtraText: 'and securely linked to your application.',
  },
  verificationStatus: {
    successTitleText: 'CONGRATS!',
    expiredTitleText: 'EXPIRED',
    notActiveTitleText: 'NOT ACTIVE',
    lpnSuccessSubtitleText: 'LPN LICENSE VERIFICATION COMPLETE',
    lpnExpiredSubtitleText: 'LPN LICENSE VERIFICATION COMPLETE',
    lpnNotActiveSubtitleText: 'LPN LICENSE VERIFICATION COMPLETE',
    rnSuccessSubtitleText: 'RN LICENSE VERIFICATION COMPLETE',
    rnExpiredSubtitleText: 'RN LICENSE VERIFICATION COMPLETE',
    rnNotActiveSubtitleText: 'RN LICENSE VERIFICATION COMPLETE',
    cnaSuccessSubtitleText: 'CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaExpiredSubtitleText: 'CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaNotActiveSubtitleText: 'CNA CERTIFICATE VERIFICATION COMPLETE',
    applicationExtraText: 'and securely linked to your application.',
    lpnSuccessDescriptionText:
      'Your license has been verified by the {{state}} Board',
    lpnExpiredDescriptionText:
      'Your license has been verified by the {{state}} Board, however it has expired. Please renew your license as soon as possible.',
    lpnNotActiveDescriptionText:
      'Your license has been verified by the {{state}} Board and it is not active.',
    rnSuccessDescriptionText:
      'Your license has been verified by the {{state}} Board',
    rnExpiredDescriptionText:
      'Your license has been verified by the {{state}} Board, however it has expired. Please renew your license as soon as possible.',
    rnNotActiveDescriptionText:
      'Your license has been verified by the {{state}} Board and it is not active.',
    cnaSuccessDescriptionText:
      'Your certificate has been verified by the {{state}} Board',
    cnaExpiredDescriptionText:
      'Your certificate has been verified by the {{state}} Board, however it has expired. Please renew your certificate as soon as possible.',
    cnaNotActiveDescriptionText:
      'Your certificate has been verified by the {{state}} Board and it is not active.',
    subDescriptionText: 'We’ll be in touch soon regarding next steps.',
    boldText: 'Best of luck!',
    buttonText: 'SEE COMPANY PROFILE',
    acceptedDescriptionText: 'The credentials have been submitted for review.',
    acceptedSubDescriptionText:
      'If you’re an employer, you will also need to review the results on the employee or candidate page. \n\nOtherwise, you’re good to go!',
  },
  verificationError: {
    lpnTitleText: 'LPN LICENSE VERIFICATION',
    lpnSubtitleText: 'Something went wrong...',
    lpnDescriptionText:
      'We’re sorry, but we were unable to verify your license with the details provided.',
    lpnDescriptionTextError503:
      'Unfortunately we were unable to establish a connection to Nursys to verify your license. We will try again on your behalf in 1 hour.',
    lpnButtonText: 'TRY AGAIN',
    rnTitleText: 'RN LICENSE VERIFICATION',
    rnSubtitleText: 'Something went wrong...',
    rnDescriptionText:
      'We’re sorry, but we were unable to verify your license with the details provided.',
    rnDescriptionTextError503:
      'Unfortunately we were unable to establish a connection to Nursys to verify your license. We will try again on your behalf in 1 hour.',
    rnButtonText: 'TRY AGAIN',
    cnaTitleText: 'CNA CERTIFICATE VERIFICATION',
    cnaSubtitleText: 'Something went wrong...',
    cnaDescriptionText:
      'We’re sorry, but we were unable to verify your license with the details provided.',
    cnaDescriptionTextError503:
      'Unfortunately we were unable to establish a connection to Nursys to verify your certificate. We will try again on your behalf in 1 hour.',
    cnaButtonText: 'TRY AGAIN',
  },
  verificationSuccess: {
    lpnTitleText: 'LPN LICENSE VERIFICATION COMPLETE',
    lpnDescriptionText:
      'You have already verified your license with the {{state}} License Board.',
    lpnButtonText: 'SEE COMPANY PROFILE',
    rnTitleText: 'RN LICENSE VERIFICATION COMPLETE',
    rnDescriptionText:
      'You have already verified your license with the {{state}} License Board.',
    rnButtonText: 'SEE COMPANY PROFILE',

    cnaTitleText: 'CNA CERTIFICATE VERIFICATION COMPLETE',
    cnaDescriptionText:
      'You have already verified your certificate with the {{state}} Board.',
    cnaButtonText: 'SEE COMPANY PROFILE',
  },

  lpnVerification: {
    lpnTitleText: 'LPN LICENSE VERIFICATION',
    rnTitleText: 'RN LICENSE VERIFICATION',
    subtitleText:
      'Please enter your information below to process your verification on the {{state}} License Board.',
    licenseNumber: 'License Number',
    licenseNumberPopup:
      'Contains 6 characters or more e.g. GA12F5\n\nSupported characters include alphanumeric characters and hyphens only.',
    licenseNumberTextWarning: 'Please enter your license number',
    licenseNumberNotSupported:
      'This License number contains unsupported characters. Please reenter your License number.',
    ssnNumber: 'Last 4 Digits of SSN',
    ssnNumberTextWarning: 'Please enter the last 4 digits of your SSN',
    birthYear: 'What year were you born',
    birthYearTextPlaceholder: 'Select Year',
    birthYearTextWarning: 'Please select your year of birth',
    dragTitleText: 'Upload your license',
    dragTitleOptionalText: '(optional)',
    dragSubTitleText: 'Drop your file here or click to upload',
    dragFileType: 'Accepted files: "pdf,docx,doc,jpg,jpeg,png"',
    dragFileSize: 'Max. size: 10MB',
    submitButtonText: 'SUBMIT',
    footerText:
      'By providing these credentialing details, you are agreeing to our',
    termsAndConditions: 'Terms and Conditions.',
  },
  svVerification: {
    typeTitleText: '{{verificationType}} CERTIFICATE VERIFICATION',
    subtitleText: 'Enter information as appears on your {{state}} Certificate.',
    certificateNumber: 'Certificate Number',
    certificateNumberPlaceholder: 'Enter Certificate Number',
    certificateNumberWarning: 'Please enter your Certificate Number',
    certificateNumberNotSupported:
      'This Credential number contains unsupported characters. Please reenter your Credential number.',
    certificateNumberPopup:
      'Contains 6 characters or more e.g. GA12F5\n\nSupported characters include alphanumeric characters and hyphens only.',
    firstName: 'First Name',
    firstNamePlaceholder: 'Enter First Name',
    firstNameWarning: 'Please enter your First Name',
    lastName: 'Last Name',
    lastNamePlaceholder: 'Enter Last Name',
    lastNameWarning: 'Please enter your Last Name',
    lastFourSsn: 'Last 4 Digits of SSN',
    lastFourSsnPlaceholder: '****',
    lastFourSsnWarning: 'Please enter the last four digits of SSN',
    dragTitleText: 'Upload your license',
    dragTitleOptionalText: '(optional)',
    dragSubTitleText: 'Drop your file here or click to upload',
    dragFileType: 'Accepted files: "pdf,docx,doc,jpg,jpeg,png"',
    dragFileSize: 'Max. size: 10MB',
    submitButtonText: 'Verify Credential',
    footerText:
      'By providing these credentialing details, you are agreeing to our',
    termsAndConditions: 'Terms and Conditions.',
  },
  svVerificationSuccess: {
    titleText: 'The credentials have been submitted for review.',
    descriptionText:
      'If you’re an employer, you will also need to review the results on the employee or candidate page. \n\nOtherwise, you’re good to go!',
  },
  svVerificationError: {
    errorTitleText: 'Something went wrong...',
    descriptionText: '{{verificationType}} Certificate Verification failed',
    errorFooterText:
      'Sorry. We were unable to verify the {{verificationType}} certificate.',
    notFoundTitleText: 'No Results',
    notFoundFooterText:
      'We were unable to verify the {{verificationType}} certificate with the details provided.',
    buttonText: 'Try Again',
  },
};

export default en;
